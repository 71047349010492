<template>
  <div>
    <div style="text-align: center;">
      <img :src="profilePictureUrl" :style="profileImageStyle" />
      <div v-if="teamName"><b>{{ teamName }}</b></div>
    </div>
    <div v-if="!isProfilePhotoOnly">
      <div class="tabs is-centered">
        <ul>
          <li :class="{ 'is-active': activeTab === 'stamps' }" @click="activeTab = 'stamps'"><a>Stamps</a></li>
          <li :class="{ 'is-active': activeTab === 'workshops' }" @click="activeTab = 'workshops'"><a>Workshops</a></li>
        </ul>
      </div>
      <div v-if="activeTab === 'stamps'" style="display: grid; grid-template-columns: 1fr 1fr; gap: 10px;">
        <div style="text-align: center;">
          <img v-if="stamps.stamp1" src="./assets/ai_stamp.svg">
          <img v-else src="./assets/common_stamp.svg">
        </div>
        <div style="text-align: center;">
          <img v-if="stamps.stamp2" src="./assets/cs_stamp.svg">
          <img v-else src="./assets/common_stamp.svg">
        </div>
        <div style="text-align: center;">
          <img v-if="stamps.stamp3" src="./assets/ft_stamp.svg">
          <img v-else src="./assets/common_stamp.svg">
        </div>
        <div style="text-align: center;">
          <img v-if="stamps.stamp4" src="./assets/it_stamp.svg">
          <img v-else src="./assets/common_stamp.svg">
        </div>
      </div>
      <div v-if="activeTab === 'workshops'" style="display: grid; grid-template-columns: 1fr 1fr; gap: 10px;">
        <div style="text-align: center;">
          <img v-if="workshops.workshop1" src="./assets/aiworkshop.png">
          <img v-else src="./assets/joinworkshop.png">
        </div>
        <div style="text-align: center;">
          <img v-if="workshops.workshop2" src="./assets/cyberworkshop.png">
          <img v-else src="./assets/joinworkshop.png">
        </div>
        <div style="text-align: center;">
          <img v-if="workshops.workshop3" src="./assets/dbftworkshop.png">
          <img v-else src="./assets/joinworkshop.png">
        </div>
        <div style="text-align: center;">
          <img v-if="workshops.workshop4" src="./assets/ditworkshop.png">
          <img v-else src="./assets/joinworkshop.png">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebaseApp from '@/firebase/init'

export default {
  name: 'NypPassport',
  props: {
    teamCode: {
      type: String,
      required: true
    },
    isProfilePhotoOnly: {
      type: Boolean,
      default: false
    },
    teamName: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      activeTab: 'stamps',
      profilePictureUrl: '',
      profileImageStyle: {
        borderRadius: '50%',
        maxHeight: '150px',
        boxShadow: ''
      },
      stamps: {
        stamp1: false,
        stamp2: false,
        stamp3: false,
        stamp4: false
      },
      workshops: {
        workshop1: false,
        workshop2: false,
        workshop3: false,
        workshop4: false
      }
    }
  },
  mounted () {
    const getNypPassportData = firebaseApp.functions('asia-northeast1').httpsCallable('getNypPassportData')
    getNypPassportData({
        teamCode: this.teamCode
    }).then(result => {
        console.log(result.data)
        this.profilePictureUrl = result.data.avatarImageUrl || this.profilePictureUrl
        this.profileImageStyle.boxShadow = `0 0 10px ${this.getHtmlColour(this.profilePictureUrl)}, 0 0 15px ${this.getHtmlColour(this.profilePictureUrl)}`
        this.stamps.stamp1 = result.data.stamp1
        this.stamps.stamp2 = result.data.stamp2
        this.stamps.stamp3 = result.data.stamp3
        this.stamps.stamp4 = result.data.stamp4
        this.workshops.workshop1 = result.data.workshop1
        this.workshops.workshop2 = result.data.workshop2
        this.workshops.workshop3 = result.data.workshop3
        this.workshops.workshop4 = result.data.workshop4
    }).catch(err => {
        console.log(err)
    })
  },
  methods: {
    getHtmlColour (url) {
      let hash = 0
      for (let i = 0; i < url.length; i++) {
        hash = url.charCodeAt(i) + ((hash << 5) - hash)
      }
      let colour = '#'
      for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xFF
        value = Math.min(255, value + 128)
        colour += ('00' + value.toString(16)).substr(-2)
      }
      return colour
    }
  }
}
</script>
